import { InputField } from "./InputField";
import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import { loadDataSelector } from "@/store/features/load-info/loadInfoSelectors";
import {
  tripDataLoadingSelector,
  tripDataSelector,
} from "@/store/features/trip-info/tripInfoSelectors";
import { Loader } from "../loader";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { Switch } from "@nextui-org/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  setActiveUnitSystem,
  setFuelPrice,
  setLoading,
} from "@/store/features/trip-info/tripInfoSlice";
import { getFuelDataByOrigin, updatePricePrecision } from "@/modules/server";

export const CalculatorView = () => {
  const dispatch = useAppDispatch();
  const loadInfo = useAppSelector(loadDataSelector);
  const tripInfo = useAppSelector(tripDataSelector);
  const loading = useAppSelector(tripDataLoadingSelector);

  const {
    distance,
    tollCost,
    fuelPrice,
    activeUnitSystem,
    isUnitsSwitchAccessible,
  } = tripInfo || {};

  const [toll, setToll] = useState(0);
  const [miles, setMiles] = useState(0);
  const [rpm, setRpm] = useState(0);
  const [consumption, setConsumption] = useState(6.8);
  const [rate, setRate] = useState(0);
  const [fuelCost, setFuelCost] = useState(0);
  const [spendings, setSpendings] = useState(0);

  const calculateFuelCost = useCallback(
    (miles: number, consumption: number) => {
      const fuel =
        activeUnitSystem === "metric" ? +fuelPrice.liter : +fuelPrice.gallon;

      const fuelCost =
        activeUnitSystem === "metric"
          ? (miles / 100) * consumption * fuel
          : (miles / consumption) * fuel;

      return +fuelCost.toFixed(2);
    },
    [activeUnitSystem, fuelPrice.gallon, fuelPrice.liter]
  );

  useEffect(() => {
    if (tripInfo) {
      const consumption = activeUnitSystem === "metric" ? 35 : 6.8;
      const miles =
        activeUnitSystem === "metric"
          ? distance.kilometersRaw
          : distance.milesRaw;
      const toll =
        activeUnitSystem === "metric" ? +tollCost.cad : +tollCost.usd;

      const fuelCost = calculateFuelCost(miles, consumption);

      setConsumption(consumption);
      setMiles(miles);
      setToll(toll);
      setFuelCost(fuelCost);
    }
  }, [
    activeUnitSystem,
    calculateFuelCost,
    distance.kilometersRaw,
    distance.milesRaw,
    fuelPrice.gallon,
    fuelPrice.liter,
    tollCost.cad,
    tollCost.usd,
    tripInfo,
  ]);

  useEffect(() => {
    const rate = +(loadInfo?.rate || 0);
    const miles = +(loadInfo?.trip || 0);

    setRate(rate);
    setRpm(+(rate / miles).toFixed(2));
  }, [loadInfo, miles]);

  useEffect(() => {
    if (!loadInfo?.origin) return;

    getFuelPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadInfo?.origin]);

  const getFuelPrice = async () => {
    dispatch(setLoading(true));

    getFuelDataByOrigin(loadInfo?.origin || "")
      .then((fuelPrice) => {
        const isCanadian = fuelPrice.country?.toLowerCase() === "canada";
        updatePricePrecision(fuelPrice);
        dispatch(
          setFuelPrice({
            gallon: isCanadian
              ? (+fuelPrice.diesel * 3.785).toFixed(2)
              : (+fuelPrice.diesel).toString(),
            liter: isCanadian
              ? (+fuelPrice.diesel).toString()
              : (+fuelPrice.diesel / 3.785).toFixed(2),
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const currencySign = useMemo(
    () => (activeUnitSystem === "metric" ? "C$" : "$"),
    [activeUnitSystem]
  );

  const consumptionTerm = useMemo(
    () => (activeUnitSystem === "metric" ? "L/100km" : "MPG"),
    [activeUnitSystem]
  );

  const rpmTerm = useMemo(
    () => (activeUnitSystem === "metric" ? "RPK" : "RPM"),
    [activeUnitSystem]
  );

  const handleUnitChange = (value: boolean) => {
    dispatch(setActiveUnitSystem(value ? "metric" : "imperial"));
    setConsumption(value ? 35 : 6.8);
  };

  const handleRPMChange = (value: number) => {
    setRpm(value);
    setRate(+(miles * value).toFixed(2));
  };

  const handleRateChange = (value: number) => {
    setRate(value);
    setRpm(+(value / miles).toFixed(2));
  };

  const handleConsumptionChange = (value: number) => {
    setConsumption(value);
    setFuelCost(calculateFuelCost(miles, value));
  };

  const handleMilesFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupMilesValueChanged);
  const handleRpmCalculatorFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupRpmCalculatorValueChanged);
  const handleMpgFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupMpgValueChanged);
  const handleRateFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupRateValueChanged);
  const handleFuelCostFocus = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupFuelCostValueChanged);

  if (!tripInfo || !loadInfo) return <div></div>;

  return (
    <div className="relative flex flex-col justify-between bg-brand-blue-700 text-white">
      <div className="h-full flex flex-col gap-2 p-3">
        <div className="flex w-full justify-between">
          <h2 className="font-semibold text-xl">Calculator</h2>
          {isUnitsSwitchAccessible && (
            <div className="flex gap-1 items-center">
              <small>mi</small>
              <Switch
                size="sm"
                isSelected={activeUnitSystem === "metric"}
                onValueChange={handleUnitChange}
                classNames={{
                  wrapper: "mr-0 !bg-brand-700",
                }}
              />
              <small>km</small>
            </div>
          )}
        </div>
        <div className="h-full flex flex-col gap-2">
          <div className="w-full grid grid-cols-3 gap-2">
            <InputField
              label="Trip"
              min={0}
              value={miles.toString()}
              onValueChange={setMiles}
              onFocus={handleMilesFocus}
            />
            <InputField
              label={rpmTerm}
              step={0.01}
              min={0}
              value={rpm.toString()}
              onValueChange={handleRPMChange}
              onFocus={handleRpmCalculatorFocus}
            />
            <InputField
              label={consumptionTerm}
              step={0.01}
              min={0.01}
              value={consumption.toString()}
              onValueChange={handleConsumptionChange}
              onFocus={handleMpgFocus}
            />
            <InputField
              label="Rate"
              min={0}
              step={10}
              value={rate.toString()}
              onValueChange={handleRateChange}
              onFocus={handleRateFocus}
            />
            <InputField
              label="Fuel"
              min={0}
              step={0.01}
              value={fuelCost.toString()}
              onValueChange={setFuelCost}
              onFocus={handleFuelCostFocus}
            />
            <InputField
              label="Spends"
              min={0}
              step={0.01}
              value={spendings.toString()}
              onValueChange={setSpendings}
            />
          </div>
        </div>
      </div>
      <div className="h-[50px] shrink-0 px-4 py-5 flex justify-between items-center bg-brand-700">
        <h2 className="font-normal text-md">Profit</h2>
        <p className="font-semibold text-md">
          {currencySign} {(+rate - fuelCost - +toll - spendings).toFixed(2)}
        </p>
      </div>
      <Loader dark show={loading} />
    </div>
  );
};
