export const AMPLITUDE_EVENTS = {
  /* General */
  popupGoogleMapsClicked: "popupGoogleMapsClicked",
  popupDeadheadClicked: "popupDeadheadClicked",
  popupClosed: "popupClosed",
  /* Calculator */
  popupRpmCalculatorValueChanged: "popupRpmCalculatorValueChanged",
  popupMilesValueChanged: "popupMilesValueChanged",
  popupMpgValueChanged: "popupMpgValueChanged",
  popupRateValueChanged: "popupRateValueChanged",
  popupFuelCostValueChanged: "popupFuelCostValueChanged",
  /* Tabs */
  popupEmailTabClicked: "popupEmailTabClicked",
  popupLoadInfoTabClicked: "popupLoadInfoTabClicked",
  popupReportTabClicked: "popupReportTabClicked",
  /* Email */
  popupEmailSenderSwitched: "popupEmailSenderSwitched",
  popupTemplateSwitched: "popupTemplateSwitched",
  popupNewTemplateClicked: "popupNewTemplateClicked",
  popupEmailBodyValueChanged: "popupEmailBodyValueChanged",
  popupSendEmailClicked: "popupSendEmailClicked",
  popupVariablesClicked: "popupVariablesClicked",
  /* Load Info */
  popupPhoneNumberClicked: "popupPhoneNumberClicked",
  /* Factoring */
  popupFactoringECapitalButtonClicked: "popupFactoringECapitalButtonClicked",
};
